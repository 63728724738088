import React, { useState } from "react";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/info-site/Header.module.css";
import Logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { headerMenu } from "../../data/header"

const Header = ({location}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const isCurrentUrl = (pathname, childrenUrl = []) => {
        return {
          selected: location.pathname.replace(/\//g, "") === pathname.replace(/\//g, ""),
          isParentSelected: childrenUrl.indexOf(location.pathname) !== -1
        };
    };

    const renderLink = (url, id, childrenUrl, parent) => {
      const checkSelected = isCurrentUrl(url, childrenUrl)

      if(!url.match(/^https:/)) {
        return (
          <Link className={`${checkSelected.isParentSelected ? styles.HeaderMenuParentLink__selected : ""} ${checkSelected.selected ? styles.selected : ""}`} to={url}>
            <FormattedMessage id={id} />
          </Link>
        )
      }

      return (
        <a href={url}>
          <FormattedMessage id={id} />
        </a>
      )

    }

    const renderSubLinks = (subMenu) => {
      return subMenu.map(({ id, url}) => (
        <li className={styles.HeaderMenuNavSubListItem} key={id.toLowerCase()}>
          {renderLink(url, id)}
        </li>
      ))
    }

    const renderMainLinks = (menuItems) => {
      return menuItems.map(({ id, url, type, items}) => {
        const childrenUrl = items?.map(({ url: itemUrl }) => itemUrl)
        return (
          <li className={`${styles.HeaderMenuNavListItem} ${styles.HeaderMenuNavListMainItem} ${type === 'request' ? styles.HeaderMenuNavListItemRequest : ''} ${type === 'login' ? styles.HeaderMenuNavListItemLogIn : ''}`} key={id.toLowerCase()}>
            {renderLink(url, id, childrenUrl, true)}
            {
              items && (
                <>
                  <span className={styles.HeaderMenuNavArrow}>
                    <FontAwesomeIcon width={24} icon={["fas", "angle-down"]} />
                  </span>
                  <ul className={`${styles.HeaderMenuNavSubList}`}>
                    {
                      renderSubLinks(items)
                    }
                  </ul>
                </>
              )
            }
          </li>
        )
      });
    };

    return (
        <div className={styles.Header}> 
            <div className={styles.HeaderPlaceholder}>
                <Link className={styles.HeaderLogo} to="/"><img src={Logo} alt="Tyviso.com" /></Link>
                <div className={`${styles.HeaderMenu} ${isMenuOpen ? styles.open : ""}`}>
                    <nav className={styles.HeaderMenuNav}>
                        <ul className={styles.HeaderMenuNavList}>
                          {renderMainLinks(headerMenu)}
                        </ul>
                    </nav>
                    <ul className={styles.SideLinks}>
                      <li className={`${styles.HeaderMenuNavListItem} ${styles.HeaderMenuNavListItemRequest}`} >
                        <Link to="/request-demo">
                          <FormattedMessage id="requestADemo" />
                        </Link>
                      </li>
                      <li className={styles.HeaderMenuNavListItemLogIn} >
                        <a href="/platform/sign-in">
                          <FormattedMessage id="logIn" />
                        </a>
                      </li>
                    </ul>
                    <div className={styles.HeaderMenuButton} onClick={() => setIsMenuOpen(!isMenuOpen)}><span></span></div>
                </div>
            </div>
        </div>
    );
};

export default Header;