import React from "react";
import Layout from "./i18n";
import Header from "../components/info-site/Header";
import Footer from "../components/Footer";
// import "../assets/fonts/fonts.css"
import CookieConsent from "../components/CookieConsent";

import "react-responsive-carousel/lib/styles/carousel.min.css";
// import 'swiper/css/swiper.css'
import "../styles/main.css";

const DefaultLayout = (props) => {
    const FOOTER_LINKS = [
        // {path: "/", key: "whatWeOffer"},
        // {path: "/setup-guide", key: "integrationGuideTitle"},
        // {path: "/faq", key: "faqShortTitle"},
        // {path: "/api", key: "api"},
        // {path: "/ad-specs", key: "adSpecsTitle"},
        // {path: "https://blog.tyviso.com/", key: "blog"},
        // {path: "/about-us", key: "aboutUs"},
        // {path: "/privacy-policy", key: "privacyPolicy"}
        {path: "/", key: "home"},
        {path: "/terms-of-service", key: "termsOfService"},
        {path: "/privacy-policy", key: "privacyPolicy"},
        // {path: "/", key: "imprint"}
    ];

    return (
        <Layout {...props}>
            <div className={`layout${props.light ? ' layout_light' : ''}`} >
                <CookieConsent />
                <Header location={props.location} />
                <div className="content">
                    {props.children}
                </div>
                <Footer links={FOOTER_LINKS} />
            </div>
        </Layout>
    );
};

export default DefaultLayout;