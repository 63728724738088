export const headerMenu = [
	{
		id: 'ourProducts', 
		url: '/tyviso-gwp',
		items: [
			{ id: 'tyvisoGWP', url: '/tyviso-gwp' },
			{ id: 'tyvisoRewards', url: '/tyviso-rewards' },
			{ id: 'tyvisoGAP', url: '/tyviso-gap' },
			// { id: 'tyvisoRecommends', url: '/tyviso-recommends' },
			// { id: 'tyvisoGo', url: '/tyviso-go' },
		],
	},
	{
		id: 'successStories',
		url: '/success-stories',
	},
	{
		id: 'integration',
		url: '/integration',
	},
	{
		id: 'aboutUs',
		url: '/about-us/our-partners',
		items: [
			// { id: 'ourStory', url: '/about-us' },
			// { id: "tyvisoStudio", url: "/about-us/tyviso-studios" },
			{ id: 'ourPartners', url: '/about-us/our-partners' },
			{ id: 'pressLinks', url: '/about-us/press-links' },
			// { id: 'contact', url: '/contact' },
		],
	},
	{
		id: 'contact',
		url: '/contact',
	},
	// {
	// 	id: 'yourGoals',
	// 	url: '/your-goals/acquire-new-customers',
	// 	items: [
	// 		{
	// 			id: 'acquireNewCustomers',
	// 			url: '/your-goals/acquire-new-customers',
	// 		},
	// 		{
	// 			id: 'raiseCustomerLifetime',
	// 			url: '/your-goals/raise-customer-lifetime',
	// 		},
	// 		// {
	// 		// 	id: 'monetiseYourEcommerce',
	// 		// 	url: '/your-goals/monetise-your-ecommerce',
	// 		// },
	// 		{
	// 			id: 'upliftBasketCompletion',
	// 			url: '/your-goals/uplift-basket-completion',
	// 		},
	// 	],
	// },
	// {
	// 	id: 'learn',
	// 	url: '/learn-more',
	// 	items: [
	// 		{ id: 'gwpSetuGuide', url: '/gift-with-purchase-setup-guide' },
	// 		{ id: 'rewardsSetuGuide', url: '/rewards-setup-guide' },
	// 		// { id: 'integrationGuide', url: '/setup-guide' },
	// 		{ id: 'gapGuide', url: '/gap-setup-guide' },
	// 		{ id: 'adSpecs', url: '/ad-specs' },
	// 		{ id: 'blog', url: 'https://blog.tyviso.com/' },
	// 		// { id: "eGuides", url: "/e-guides" },
	// 	],
	// },
	{
		id: 'requestADemo',
		type: 'request',
		url: '/request-demo',
	},
	{
		id: 'logIn',
		type: 'login',
		url: '/platform/sign-in',
	},
];